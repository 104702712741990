import { Suspense, Fragment } from "react";
import { Routes, Route, RouteProps } from "react-router-dom";
import pages from "./routes";
import AuthGuard from "./guards/AuthGuard";
import LazyLoad from "../components/LazyLoad";
import GroupRestrictionGuard from "./guards/GroupRestrictionGuard";
import PageTitle from "../components/PageTitle";

export type RouteConfig = {
  path: string;
  component: React.ComponentType<any>;
  guard?: React.ComponentType<any> | typeof Fragment | any;
  layout?: React.ComponentType<any> | typeof Fragment;
  groups?: string[];
  title?: string;
} & RouteProps;

export const renderRoutes = (routes: RouteConfig[] = []) => (
  <Suspense fallback={<LazyLoad />}>
    <Routes>
      {routes.map((route, index) => {
        const Component = route.component;
        const Guard = route?.guard || Fragment;
        const Layout = route?.layout || Fragment;
        const isAuthGuard = Guard === AuthGuard;
        const title = route?.title ? `SAES:RPG - ${route?.title}` : "SAES:RPG - Web Portal";

        return (
          <Route
            key={index}
            path={route.path}
            element={
              <PageTitle title={title}>
                <Guard>
                  {isAuthGuard ? (
                    <GroupRestrictionGuard groupRestrictions={route?.groups}>
                      <Layout>
                        <Component />
                      </Layout>
                    </GroupRestrictionGuard>
                  ) : (
                    <Layout>
                      <Component />
                    </Layout>
                  )}
                </Guard>
              </PageTitle>
            }
          />
        );
      })}
    </Routes>
  </Suspense>
);

const routes: RouteConfig[] = [...pages];

export default routes;
