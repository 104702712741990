import { combineReducers } from "@reduxjs/toolkit";
import userReducer from "./slices/user";
import loaderReducer from "./slices/loader";

const rootReducer = combineReducers({
  auth: userReducer,
  loader: loaderReducer,
});

export default rootReducer;
