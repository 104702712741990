import { lazy } from "react";
import GuestGuard from "./guards/GuestGuard";
import AuthGuard from "./guards/AuthGuard";
import { RouteConfig } from "./index";

const GuestLayout = lazy(() => import("../layout/GuestLayout"));
const MainLayout = lazy(() => import("../layout/MainLayout"));

const routes: RouteConfig[] = [
  {
    guard: GuestGuard,
    path: "/",
    component: lazy(() => import("../pages/Login")),
    layout: GuestLayout,
    title: "Login",
  },

  {
    guard: AuthGuard,
    path: "/player-stats",
    component: lazy(() => import("../pages/PlayerStats")),
    layout: MainLayout,
    title: "Player Stats",
  },

  {
    guard: AuthGuard,
    path: "/leaderboards/stats",
    component: lazy(() => import("../pages/StatsLeaderboard")),
    layout: MainLayout,
    title: "Stats Leaderboard",
  },
  {
    guard: AuthGuard,
    path: "/leaderboards/races",
    component: lazy(() => import("../pages/RacesLeaderboard")),
    layout: MainLayout,
    title: "Races Leaderboard",
  },
  {
    guard: AuthGuard,
    path: "/spawn-time",
    component: lazy(() => import("../pages/SpawnStats")),
    layout: MainLayout,
    title: "Spawn Time",
  },
  {
    guard: AuthGuard,
    path: "/donations",
    component: lazy(() => import("../pages/Donations")),
    layout: MainLayout,
    title: "Donations",
  },

  {
    guard: AuthGuard,
    path: "/map-converter",
    component: lazy(() => import("../pages/MapConverter")),

    layout: MainLayout,
    title: "Map Converter",
  },

  {
    guard: AuthGuard,
    path: "/web-admin/players",
    component: lazy(() => import("../pages/WebAdmin")),
    layout: MainLayout,
    groups: ["staff", "subadmin", "admin", "semiadmin", "saeshq"],
    title: "Web Admin",
  },
  {
    guard: AuthGuard,
    path: "/web-admin/reports",
    component: lazy(() => import("../pages/Reports")),
    layout: MainLayout,
    groups: ["staff", "subadmin", "admin", "semiadmin", "saeshq"],
    title: "Reports",
  },
  {
    guard: AuthGuard,
    path: "/logs-visualiser",
    component: lazy(() => import("../pages/LogsVisualiser")),
    layout: GuestLayout,
    groups: ["staff", "subadmin", "admin", "semiadmin", "saeshq"],
    title: "Logs Visualiser",
  },
  {
    guard: AuthGuard,
    path: "/web-admin/resources",
    component: lazy(() => import("../pages/Resources")),
    layout: MainLayout,
    groups: ["saeshq", "runcode", "gang-management", "gm", "creative"],
    title: "Resources",
  },
  {
    guard: AuthGuard,
    path: "/web-admin/runcode",
    component: lazy(() => import("../pages/Runcode")),
    layout: MainLayout,
    groups: ["runcode"],
    title: "Runcode",
  },
  {
    guard: AuthGuard,
    path: "/logs/admin",
    component: lazy(() => import("../pages/AdminLogs")),
    layout: MainLayout,
    groups: ["subadmin", "admin", "semiadmin", "saeshq"],
    title: "Admin Logs",
  },
  {
    guard: AuthGuard,
    path: "/logs/housing",
    component: lazy(() => import("../pages/HousingLogs")),
    layout: MainLayout,
    groups: ["subadmin", "admin", "semiadmin", "saeshq"],
    title: "Housing Logs",
  },
  {
    guard: AuthGuard,
    path: "/cs-stats",
    component: lazy(() => import("../pages/CommunityStaffStats")),
    layout: MainLayout,
    groups: ["cs-leadership", "saeshq", "runcode"],
    title: "CS Stats",
  },
  {
    guard: AuthGuard,
    path: "/gm-stats",
    component: lazy(() => import("../pages/GangManagementStats")),
    layout: MainLayout,
    groups: ["gang-management", "saeshq", "runcode"],
    title: "GM Stats",
  },
  {
    path: "*",
    component: lazy(() => import("../pages/NavigationError")),
    title: "Not Found",
  },
];

export default routes;
