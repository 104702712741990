import { createSlice } from "@reduxjs/toolkit";

interface LoaderState {
  pageLoading: boolean;
  dataLoading: boolean;
}

const initialState: LoaderState = {
  pageLoading: false,
  dataLoading: false,
};

export const loaderSlice = createSlice({
  name: "loader",
  initialState,
  reducers: {
    setPageLoading: (state, action) => {
      state.pageLoading = action.payload;
    },
    setDataLoading: (state, action) => {
      state.dataLoading = action.payload;
    },
  },
});

export default loaderSlice.reducer;

export const { setPageLoading, setDataLoading } = loaderSlice.actions;
