import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axiosInstance from "../utils/axiosInstance";
import { clearAccessToken, getAccessToken } from "../utils/token";
import { RootState } from "../store";
import useIsMountedRef from "../hook/useIsMountedRef";
import { initialise } from "../store/slices/user";
import { jwtDecode } from "jwt-decode";
import LazyLoad from "../components/LazyLoad";

interface AuthProviderProps {
  children: React.ReactNode;
}

interface TokenPayload {
  id: string;
  iat: number;
  exp: number;
}

const AuthProvider = ({ children }: AuthProviderProps) => {
  const isMounted = useIsMountedRef();

  const { isInitialised } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();

  const isValidToken = (token: string) => {
    const { exp } = getTokenPayload(token);
    const currentTime = Date.now() / 1000;
    return exp > currentTime;
  };

  const getTokenPayload = (token: string): TokenPayload => {
    return jwtDecode(token);
  };

  useEffect(() => {
    if (!isMounted.current) {
      return;
    }

    async function fetchUser() {
      const access_token = getAccessToken();
      try {
        if (access_token && typeof access_token === "string" && isValidToken(access_token)) {
          const response = await axiosInstance.get("/me");
          if (response.status === 200) {
            return dispatch(initialise({ isAuthenticated: true, user: response?.data?.data?.user }));
          }
        }
        clearAccessToken();
        dispatch(initialise({ isAuthenticated: false, user: null }));
      } catch (error) {
        console.error("An error occurred while fetching user:", error);
        if (access_token) clearAccessToken();
        dispatch(initialise({ isAuthenticated: false, user: null }));
      }
    }

    fetchUser();
  }, []);

  if (!isInitialised) {
    return <LazyLoad />;
  }

  return <>{children}</>;
};

export default AuthProvider;
