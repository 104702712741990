import React from "react";
import saesLogo from "../../images/saes-logo.webp";
import { Link } from "react-router-dom";

interface NagivationErrorProps {
  title?: string;
  subtitle?: string;
}

const NavigationError: React.FC<NagivationErrorProps> = ({ title = "404 Not Found", subtitle = "Sorry, the page you are looking for does not exist." }) => {
  return (
    <div className="flex flex-col gap-6 justify-center items-center h-dvh">
      <Link to="/">
        <img src={saesLogo} className="h-[25dvh]" alt="saes logo" />
      </Link>

      <h1 className="text-center text-8xl">{title}</h1>
      <p className="text-center text-2xl">{subtitle}</p>
    </div>
  );
};

export default NavigationError;
