import { Navigate, useLocation } from "react-router-dom";
import { useAppSelector } from "../../store";

interface MainLayoutProps {
  children: React.ReactNode;
}

const AuthGuard = ({ children }: MainLayoutProps) => {
  const location = useLocation();

  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);
  return isAuthenticated ? children : <Navigate to="/" state={{ from: location }} />;
};

export default AuthGuard;
