import NavigationError from "../../features/NavigationError";
import { useAppSelector } from "../../store";
import { hasAccessTo } from "../../utils/accessControl";

interface MainLayoutProps {
  children: React.ReactNode;
  groupRestrictions?: string[];
}

const GroupRestrictionGuard = ({ children, groupRestrictions }: MainLayoutProps) => {
  const { groups } = useAppSelector((state) => state.auth.user)!;

  if (groupRestrictions && groupRestrictions.length > 0) {
    if (!hasAccessTo(groups, groupRestrictions)) {
      return <NavigationError title="403 Access denied" subtitle="Sorry, you are not authorized to access this page." />;
    }
  }

  return <>{children}</>;
};

export default GroupRestrictionGuard;
