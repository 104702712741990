import { createSlice } from "@reduxjs/toolkit";
import { clearAccessToken } from "../../utils/token";

export interface IUser {
  id: number;
  username: string;
  groups: string[];
  gang: string;
  email: string;
}

interface UserState {
  user: IUser | null;
  isAuthenticated: boolean;
  isInitialised: boolean;
  redirect: string | null;
}

const initialState: UserState = {
  user: null,
  isAuthenticated: false,
  isInitialised: false,
  redirect: null,
};

const userSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    initialise: (state, action) => {
      const { isAuthenticated, user, redirect } = action.payload;
      state.isAuthenticated = isAuthenticated;
      state.isInitialised = true;
      state.user = user;
      if (redirect && redirect !== "") state.redirect = redirect;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      clearAccessToken();
    },
  },
});

export const { initialise, logout } = userSlice.actions;
export default userSlice.reducer;
