import { Navigate } from "react-router-dom";
import { useAppSelector } from "../../store";

interface MainLayoutProps {
  children: React.ReactNode;
}

const GuestGuard = ({ children }: MainLayoutProps) => {
  const { isAuthenticated, redirect } = useAppSelector((state) => state.auth);
  const to = redirect ? redirect : "/player-stats";
  return isAuthenticated ? <Navigate to={to} /> : children;
};

export default GuestGuard;
